import {Injectable} from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService  {

  constructor(public storage: Storage) { }

  // region Fields

  private readonly authTokenKey: string = 'AUTH_TOKEN';
  private readonly refreshTokenKey: string = 'REFRESH_TOKEN';

  public getAuthToken(): Promise<string> {
    return this.storage.get(this.authTokenKey).then((value) => {
      return value;
    });
  }

  public setAuthToken(value: string): Promise<any> {
    return this.storage.set(this.authTokenKey, value);
  }

  public clearAuthToken(): void {
    this.storage.remove(this.authTokenKey);
  }

  public getRefreshToken(): Promise<string> {
    return this.storage.get(this.refreshTokenKey).then((value) => {
      return value;
    });
  }

  public setRefreshToken(value: string): Promise<any> {
    return this.storage.set(this.refreshTokenKey, value);
  }

  public clearRefreshToken(): void {
    this.storage.remove(this.refreshTokenKey);
  }

}
