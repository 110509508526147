import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';


@Injectable()
export class ToastService {

  constructor(public toastController: ToastController) {}

  // region Toast message

  async presentToast(message: string, type: string = 'primary', header: string = '', duration: number = 2000) {
    /* type/colors
    "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", and "dark"
     */
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      duration,
      color: type,
    });
    toast.present();
  }

  public showError(message: string): void {
    this.presentToast(message, 'danger', 'Fehler');
  }

  public showSuccess(message: string): void {
    this.presentToast(message, 'success', 'Erfolg');
  }

  public readAndShowError(err: any): void {
    if (err.hasOwnProperty('error')) {
      this.showError(err.error.Error);
    } else {
      this.showError('Error');
    }
  }

  public readAnyErrorValues(err:any): void {
    let msg = '';
    if (err.hasOwnProperty('error')) {
      let error = err.error;
      if(typeof error === 'string'){
        error = JSON.parse(error);
      }
      for (const key in error) {
        if (error.hasOwnProperty(key)) {
          const value = error[key];
          msg = msg + value + '\n';
        }
      }
      this.showError(msg);
    }

  }

}
