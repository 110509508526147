/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Account } from '../models/account';
import { AccountDelete } from '../models/account-delete';
import { AccountProfilePicture } from '../models/account-profile-picture';
import { AccountRegistration } from '../models/account-registration';
import { Activation } from '../models/activation';
import { EventReadonly } from '../models/event-readonly';
import { EventeroPage } from '../models/eventero-page';
import { ExhibitorData } from '../models/exhibitor-data';
import { ExhibitorReadonly } from '../models/exhibitor-readonly';
import { LecturerData } from '../models/lecturer-data';
import { LecturerReadOnly } from '../models/lecturer-read-only';
import { PaginatedEventReadonlyList } from '../models/paginated-event-readonly-list';
import { PaginatedExhibitorDataList } from '../models/paginated-exhibitor-data-list';
import { PaginatedExhibitorShortList } from '../models/paginated-exhibitor-short-list';
import { PaginatedLecturerConfirmationList } from '../models/paginated-lecturer-confirmation-list';
import { PaginatedLecturerDataList } from '../models/paginated-lecturer-data-list';
import { PaginatedLecturerReadOnlyList } from '../models/paginated-lecturer-read-only-list';
import { PaginatedLightAccountList } from '../models/paginated-light-account-list';
import { PaginatedParticipationInformationList } from '../models/paginated-participation-information-list';
import { PaginatedSchoolTypeList } from '../models/paginated-school-type-list';
import { PaginatedTimeslotParticipationPresenceList } from '../models/paginated-timeslot-participation-presence-list';
import { PaginatedTimeslotReadonlyList } from '../models/paginated-timeslot-readonly-list';
import { PaginatedUserList } from '../models/paginated-user-list';
import { PaginatedWorkshopReadonlyList } from '../models/paginated-workshop-readonly-list';
import { ParticipationInformation } from '../models/participation-information';
import { ParticipationSurvey } from '../models/participation-survey';
import { PasswordResetConfirm } from '../models/password-reset-confirm';
import { PatchedAccount } from '../models/patched-account';
import { PatchedLecturerData } from '../models/patched-lecturer-data';
import { PatchedTimeslotParticipationPresence } from '../models/patched-timeslot-participation-presence';
import { PatchedUser } from '../models/patched-user';
import { SendEmailReset } from '../models/send-email-reset';
import { SetPassword } from '../models/set-password';
import { SetUsername } from '../models/set-username';
import { SupportQuestion } from '../models/support-question';
import { TimeslotDetail } from '../models/timeslot-detail';
import { TimeslotParticipationPresence } from '../models/timeslot-participation-presence';
import { TimeslotReadonly } from '../models/timeslot-readonly';
import { TokenObtainPair } from '../models/token-obtain-pair';
import { TokenRefresh } from '../models/token-refresh';
import { TokenVerify } from '../models/token-verify';
import { User } from '../models/user';
import { UsernameResetConfirm } from '../models/username-reset-confirm';
import { WorkshopReadonly } from '../models/workshop-readonly';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRetrieve
   */
  static readonly ApiRetrievePath = '/api/';

  /**
   * Eventero API Documentation
   * To call unsave operations like DELETE and PUT, include a crsf token ( cookie with default name csrftoken ) in the header ( X-CSRFToken )
   * Example ( curl ) : -H 'X-CSRFToken: <CSRFTOKEN FROM COOKIE>'
   *
   * dateformats if not other is specified is iso-8601 ( use JS date.toISOString() )
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRetrieve$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiRetrievePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Eventero API Documentation
   * To call unsave operations like DELETE and PUT, include a crsf token ( cookie with default name csrftoken ) in the header ( X-CSRFToken )
   * Example ( curl ) : -H 'X-CSRFToken: <CSRFTOKEN FROM COOKIE>'
   *
   * dateformats if not other is specified is iso-8601 ( use JS date.toISOString() )
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRetrieve(params?: {
  }): Observable<void> {

    return this.apiRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAccountRetrieve
   */
  static readonly ApiAccountRetrievePath = '/api/account/';

  /**
   * detail of current used Account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountRetrieve$Response(params?: {
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAccountRetrievePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * detail of current used Account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountRetrieve(params?: {
  }): Observable<Account> {

    return this.apiAccountRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation apiAccountDeleteCreate
   */
  static readonly ApiAccountDeleteCreatePath = '/api/account/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountDeleteCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAccountDeleteCreate$Json$Response(params: {
    body: AccountDelete
  }): Observable<StrictHttpResponse<AccountDelete>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAccountDeleteCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountDelete>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountDeleteCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAccountDeleteCreate$Json(params: {
    body: AccountDelete
  }): Observable<AccountDelete> {

    return this.apiAccountDeleteCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDelete>) => r.body as AccountDelete)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountDeleteCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAccountDeleteCreate$XWwwFormUrlencoded$Response(params: {
    body: AccountDelete
  }): Observable<StrictHttpResponse<AccountDelete>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAccountDeleteCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountDelete>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountDeleteCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAccountDeleteCreate$XWwwFormUrlencoded(params: {
    body: AccountDelete
  }): Observable<AccountDelete> {

    return this.apiAccountDeleteCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDelete>) => r.body as AccountDelete)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountDeleteCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountDeleteCreate$FormData$Response(params: {
    body: AccountDelete
  }): Observable<StrictHttpResponse<AccountDelete>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAccountDeleteCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountDelete>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountDeleteCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAccountDeleteCreate$FormData(params: {
    body: AccountDelete
  }): Observable<AccountDelete> {

    return this.apiAccountDeleteCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<AccountDelete>) => r.body as AccountDelete)
    );
  }

  /**
   * Path part for operation apiAuthJwtCreateCreate
   */
  static readonly ApiAuthJwtCreateCreatePath = '/api/auth/jwt/create/';

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthJwtCreateCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthJwtCreateCreate$Json$Response(params: {
    body: TokenObtainPair
  }): Observable<StrictHttpResponse<TokenObtainPair>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthJwtCreateCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenObtainPair>;
      })
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthJwtCreateCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthJwtCreateCreate$Json(params: {
    body: TokenObtainPair
  }): Observable<TokenObtainPair> {

    return this.apiAuthJwtCreateCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TokenObtainPair>) => r.body as TokenObtainPair)
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthJwtCreateCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthJwtCreateCreate$XWwwFormUrlencoded$Response(params: {
    body: TokenObtainPair
  }): Observable<StrictHttpResponse<TokenObtainPair>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthJwtCreateCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenObtainPair>;
      })
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthJwtCreateCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthJwtCreateCreate$XWwwFormUrlencoded(params: {
    body: TokenObtainPair
  }): Observable<TokenObtainPair> {

    return this.apiAuthJwtCreateCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<TokenObtainPair>) => r.body as TokenObtainPair)
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthJwtCreateCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthJwtCreateCreate$FormData$Response(params: {
    body: TokenObtainPair
  }): Observable<StrictHttpResponse<TokenObtainPair>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthJwtCreateCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenObtainPair>;
      })
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthJwtCreateCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthJwtCreateCreate$FormData(params: {
    body: TokenObtainPair
  }): Observable<TokenObtainPair> {

    return this.apiAuthJwtCreateCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<TokenObtainPair>) => r.body as TokenObtainPair)
    );
  }

  /**
   * Path part for operation apiAuthJwtRefreshCreate
   */
  static readonly ApiAuthJwtRefreshCreatePath = '/api/auth/jwt/refresh/';

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthJwtRefreshCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthJwtRefreshCreate$Json$Response(params: {
    body: TokenRefresh
  }): Observable<StrictHttpResponse<TokenRefresh>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthJwtRefreshCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenRefresh>;
      })
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthJwtRefreshCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthJwtRefreshCreate$Json(params: {
    body: TokenRefresh
  }): Observable<TokenRefresh> {

    return this.apiAuthJwtRefreshCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TokenRefresh>) => r.body as TokenRefresh)
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthJwtRefreshCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthJwtRefreshCreate$XWwwFormUrlencoded$Response(params: {
    body: TokenRefresh
  }): Observable<StrictHttpResponse<TokenRefresh>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthJwtRefreshCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenRefresh>;
      })
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthJwtRefreshCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthJwtRefreshCreate$XWwwFormUrlencoded(params: {
    body: TokenRefresh
  }): Observable<TokenRefresh> {

    return this.apiAuthJwtRefreshCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<TokenRefresh>) => r.body as TokenRefresh)
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthJwtRefreshCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthJwtRefreshCreate$FormData$Response(params: {
    body: TokenRefresh
  }): Observable<StrictHttpResponse<TokenRefresh>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthJwtRefreshCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenRefresh>;
      })
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthJwtRefreshCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthJwtRefreshCreate$FormData(params: {
    body: TokenRefresh
  }): Observable<TokenRefresh> {

    return this.apiAuthJwtRefreshCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<TokenRefresh>) => r.body as TokenRefresh)
    );
  }

  /**
   * Path part for operation apiAuthJwtVerifyCreate
   */
  static readonly ApiAuthJwtVerifyCreatePath = '/api/auth/jwt/verify/';

  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token's fitness for a particular use.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthJwtVerifyCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthJwtVerifyCreate$Json$Response(params: {
    body: TokenVerify
  }): Observable<StrictHttpResponse<TokenVerify>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthJwtVerifyCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenVerify>;
      })
    );
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token's fitness for a particular use.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthJwtVerifyCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthJwtVerifyCreate$Json(params: {
    body: TokenVerify
  }): Observable<TokenVerify> {

    return this.apiAuthJwtVerifyCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TokenVerify>) => r.body as TokenVerify)
    );
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token's fitness for a particular use.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthJwtVerifyCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthJwtVerifyCreate$XWwwFormUrlencoded$Response(params: {
    body: TokenVerify
  }): Observable<StrictHttpResponse<TokenVerify>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthJwtVerifyCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenVerify>;
      })
    );
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token's fitness for a particular use.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthJwtVerifyCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthJwtVerifyCreate$XWwwFormUrlencoded(params: {
    body: TokenVerify
  }): Observable<TokenVerify> {

    return this.apiAuthJwtVerifyCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<TokenVerify>) => r.body as TokenVerify)
    );
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token's fitness for a particular use.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthJwtVerifyCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthJwtVerifyCreate$FormData$Response(params: {
    body: TokenVerify
  }): Observable<StrictHttpResponse<TokenVerify>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthJwtVerifyCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenVerify>;
      })
    );
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token's fitness for a particular use.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthJwtVerifyCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthJwtVerifyCreate$FormData(params: {
    body: TokenVerify
  }): Observable<TokenVerify> {

    return this.apiAuthJwtVerifyCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<TokenVerify>) => r.body as TokenVerify)
    );
  }

  /**
   * Path part for operation apiAuthUsersList
   */
  static readonly ApiAuthUsersListPath = '/api/auth/users/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedUserList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedUserList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedUserList> {

    return this.apiAuthUsersList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedUserList>) => r.body as PaginatedUserList)
    );
  }

  /**
   * Path part for operation apiAuthUsersCreate
   */
  static readonly ApiAuthUsersCreatePath = '/api/auth/users/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersCreate$Json$Response(params: {
    body: AccountRegistration
  }): Observable<StrictHttpResponse<AccountRegistration>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRegistration>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersCreate$Json(params: {
    body: AccountRegistration
  }): Observable<AccountRegistration> {

    return this.apiAuthUsersCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountRegistration>) => r.body as AccountRegistration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersCreate$XWwwFormUrlencoded$Response(params: {
    body: AccountRegistration
  }): Observable<StrictHttpResponse<AccountRegistration>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRegistration>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersCreate$XWwwFormUrlencoded(params: {
    body: AccountRegistration
  }): Observable<AccountRegistration> {

    return this.apiAuthUsersCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<AccountRegistration>) => r.body as AccountRegistration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersCreate$FormData$Response(params: {
    body: AccountRegistration
  }): Observable<StrictHttpResponse<AccountRegistration>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRegistration>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersCreate$FormData(params: {
    body: AccountRegistration
  }): Observable<AccountRegistration> {

    return this.apiAuthUsersCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<AccountRegistration>) => r.body as AccountRegistration)
    );
  }

  /**
   * Path part for operation apiAuthUsersRetrieve
   */
  static readonly ApiAuthUsersRetrievePath = '/api/auth/users/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersRetrieve(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
  }): Observable<User> {

    return this.apiAuthUsersRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation apiAuthUsersUpdate
   */
  static readonly ApiAuthUsersUpdatePath = '/api/auth/users/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: User
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersUpdate$Json(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: User
  }): Observable<User> {

    return this.apiAuthUsersUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: User
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: User
  }): Observable<User> {

    return this.apiAuthUsersUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: User
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: User
  }): Observable<User> {

    return this.apiAuthUsersUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation apiAuthUsersDestroy
   */
  static readonly ApiAuthUsersDestroyPath = '/api/auth/users/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersDestroy$Response(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersDestroy(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
  }): Observable<void> {

    return this.apiAuthUsersDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAuthUsersPartialUpdate
   */
  static readonly ApiAuthUsersPartialUpdatePath = '/api/auth/users/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: PatchedUser
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: PatchedUser
  }): Observable<User> {

    return this.apiAuthUsersPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: PatchedUser
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: PatchedUser
  }): Observable<User> {

    return this.apiAuthUsersPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: PatchedUser
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
    body?: PatchedUser
  }): Observable<User> {

    return this.apiAuthUsersPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation apiAuthUsersActivationCreate
   */
  static readonly ApiAuthUsersActivationCreatePath = '/api/auth/users/activation/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersActivationCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersActivationCreate$Json$Response(params: {
    body: Activation
  }): Observable<StrictHttpResponse<Activation>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersActivationCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Activation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersActivationCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersActivationCreate$Json(params: {
    body: Activation
  }): Observable<Activation> {

    return this.apiAuthUsersActivationCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Activation>) => r.body as Activation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersActivationCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersActivationCreate$XWwwFormUrlencoded$Response(params: {
    body: Activation
  }): Observable<StrictHttpResponse<Activation>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersActivationCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Activation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersActivationCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersActivationCreate$XWwwFormUrlencoded(params: {
    body: Activation
  }): Observable<Activation> {

    return this.apiAuthUsersActivationCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Activation>) => r.body as Activation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersActivationCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersActivationCreate$FormData$Response(params: {
    body: Activation
  }): Observable<StrictHttpResponse<Activation>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersActivationCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Activation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersActivationCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersActivationCreate$FormData(params: {
    body: Activation
  }): Observable<Activation> {

    return this.apiAuthUsersActivationCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Activation>) => r.body as Activation)
    );
  }

  /**
   * Path part for operation apiAuthUsersMeRetrieve
   */
  static readonly ApiAuthUsersMeRetrievePath = '/api/auth/users/me/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersMeRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersMeRetrieve$Response(params?: {
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersMeRetrievePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersMeRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersMeRetrieve(params?: {
  }): Observable<Account> {

    return this.apiAuthUsersMeRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation apiAuthUsersMeUpdate
   */
  static readonly ApiAuthUsersMeUpdatePath = '/api/auth/users/me/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersMeUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersMeUpdate$Json$Response(params?: {
    body?: Account
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersMeUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersMeUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersMeUpdate$Json(params?: {
    body?: Account
  }): Observable<Account> {

    return this.apiAuthUsersMeUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersMeUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersMeUpdate$XWwwFormUrlencoded$Response(params?: {
    body?: Account
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersMeUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersMeUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersMeUpdate$XWwwFormUrlencoded(params?: {
    body?: Account
  }): Observable<Account> {

    return this.apiAuthUsersMeUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersMeUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersMeUpdate$FormData$Response(params?: {
    body?: Account
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersMeUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersMeUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersMeUpdate$FormData(params?: {
    body?: Account
  }): Observable<Account> {

    return this.apiAuthUsersMeUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation apiAuthUsersMeDestroy
   */
  static readonly ApiAuthUsersMeDestroyPath = '/api/auth/users/me/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersMeDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersMeDestroy$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersMeDestroyPath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersMeDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuthUsersMeDestroy(params?: {
  }): Observable<void> {

    return this.apiAuthUsersMeDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiAuthUsersMePartialUpdate
   */
  static readonly ApiAuthUsersMePartialUpdatePath = '/api/auth/users/me/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersMePartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersMePartialUpdate$Json$Response(params?: {
    body?: PatchedAccount
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersMePartialUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersMePartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersMePartialUpdate$Json(params?: {
    body?: PatchedAccount
  }): Observable<Account> {

    return this.apiAuthUsersMePartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersMePartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersMePartialUpdate$XWwwFormUrlencoded$Response(params?: {
    body?: PatchedAccount
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersMePartialUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersMePartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersMePartialUpdate$XWwwFormUrlencoded(params?: {
    body?: PatchedAccount
  }): Observable<Account> {

    return this.apiAuthUsersMePartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersMePartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersMePartialUpdate$FormData$Response(params?: {
    body?: PatchedAccount
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersMePartialUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersMePartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersMePartialUpdate$FormData(params?: {
    body?: PatchedAccount
  }): Observable<Account> {

    return this.apiAuthUsersMePartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation apiAuthUsersResendActivationCreate
   */
  static readonly ApiAuthUsersResendActivationCreatePath = '/api/auth/users/resend_activation/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResendActivationCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResendActivationCreate$Json$Response(params: {
    body: SendEmailReset
  }): Observable<StrictHttpResponse<SendEmailReset>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResendActivationCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendEmailReset>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResendActivationCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResendActivationCreate$Json(params: {
    body: SendEmailReset
  }): Observable<SendEmailReset> {

    return this.apiAuthUsersResendActivationCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SendEmailReset>) => r.body as SendEmailReset)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResendActivationCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResendActivationCreate$XWwwFormUrlencoded$Response(params: {
    body: SendEmailReset
  }): Observable<StrictHttpResponse<SendEmailReset>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResendActivationCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendEmailReset>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResendActivationCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResendActivationCreate$XWwwFormUrlencoded(params: {
    body: SendEmailReset
  }): Observable<SendEmailReset> {

    return this.apiAuthUsersResendActivationCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<SendEmailReset>) => r.body as SendEmailReset)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResendActivationCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResendActivationCreate$FormData$Response(params: {
    body: SendEmailReset
  }): Observable<StrictHttpResponse<SendEmailReset>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResendActivationCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendEmailReset>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResendActivationCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResendActivationCreate$FormData(params: {
    body: SendEmailReset
  }): Observable<SendEmailReset> {

    return this.apiAuthUsersResendActivationCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<SendEmailReset>) => r.body as SendEmailReset)
    );
  }

  /**
   * Path part for operation apiAuthUsersResetEmailCreate
   */
  static readonly ApiAuthUsersResetEmailCreatePath = '/api/auth/users/reset_email/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetEmailCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResetEmailCreate$Json$Response(params: {
    body: SendEmailReset
  }): Observable<StrictHttpResponse<SendEmailReset>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetEmailCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendEmailReset>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetEmailCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResetEmailCreate$Json(params: {
    body: SendEmailReset
  }): Observable<SendEmailReset> {

    return this.apiAuthUsersResetEmailCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SendEmailReset>) => r.body as SendEmailReset)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetEmailCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResetEmailCreate$XWwwFormUrlencoded$Response(params: {
    body: SendEmailReset
  }): Observable<StrictHttpResponse<SendEmailReset>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetEmailCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendEmailReset>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetEmailCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResetEmailCreate$XWwwFormUrlencoded(params: {
    body: SendEmailReset
  }): Observable<SendEmailReset> {

    return this.apiAuthUsersResetEmailCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<SendEmailReset>) => r.body as SendEmailReset)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetEmailCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResetEmailCreate$FormData$Response(params: {
    body: SendEmailReset
  }): Observable<StrictHttpResponse<SendEmailReset>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetEmailCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendEmailReset>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetEmailCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResetEmailCreate$FormData(params: {
    body: SendEmailReset
  }): Observable<SendEmailReset> {

    return this.apiAuthUsersResetEmailCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<SendEmailReset>) => r.body as SendEmailReset)
    );
  }

  /**
   * Path part for operation apiAuthUsersResetEmailConfirmCreate
   */
  static readonly ApiAuthUsersResetEmailConfirmCreatePath = '/api/auth/users/reset_email_confirm/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetEmailConfirmCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResetEmailConfirmCreate$Json$Response(params: {
    body: UsernameResetConfirm
  }): Observable<StrictHttpResponse<UsernameResetConfirm>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetEmailConfirmCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsernameResetConfirm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetEmailConfirmCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResetEmailConfirmCreate$Json(params: {
    body: UsernameResetConfirm
  }): Observable<UsernameResetConfirm> {

    return this.apiAuthUsersResetEmailConfirmCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UsernameResetConfirm>) => r.body as UsernameResetConfirm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetEmailConfirmCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResetEmailConfirmCreate$XWwwFormUrlencoded$Response(params: {
    body: UsernameResetConfirm
  }): Observable<StrictHttpResponse<UsernameResetConfirm>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetEmailConfirmCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsernameResetConfirm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetEmailConfirmCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResetEmailConfirmCreate$XWwwFormUrlencoded(params: {
    body: UsernameResetConfirm
  }): Observable<UsernameResetConfirm> {

    return this.apiAuthUsersResetEmailConfirmCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<UsernameResetConfirm>) => r.body as UsernameResetConfirm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetEmailConfirmCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResetEmailConfirmCreate$FormData$Response(params: {
    body: UsernameResetConfirm
  }): Observable<StrictHttpResponse<UsernameResetConfirm>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetEmailConfirmCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsernameResetConfirm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetEmailConfirmCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResetEmailConfirmCreate$FormData(params: {
    body: UsernameResetConfirm
  }): Observable<UsernameResetConfirm> {

    return this.apiAuthUsersResetEmailConfirmCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<UsernameResetConfirm>) => r.body as UsernameResetConfirm)
    );
  }

  /**
   * Path part for operation apiAuthUsersResetPasswordCreate
   */
  static readonly ApiAuthUsersResetPasswordCreatePath = '/api/auth/users/reset_password/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetPasswordCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResetPasswordCreate$Json$Response(params: {
    body: SendEmailReset
  }): Observable<StrictHttpResponse<SendEmailReset>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetPasswordCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendEmailReset>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetPasswordCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResetPasswordCreate$Json(params: {
    body: SendEmailReset
  }): Observable<SendEmailReset> {

    return this.apiAuthUsersResetPasswordCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SendEmailReset>) => r.body as SendEmailReset)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetPasswordCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResetPasswordCreate$XWwwFormUrlencoded$Response(params: {
    body: SendEmailReset
  }): Observable<StrictHttpResponse<SendEmailReset>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetPasswordCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendEmailReset>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetPasswordCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResetPasswordCreate$XWwwFormUrlencoded(params: {
    body: SendEmailReset
  }): Observable<SendEmailReset> {

    return this.apiAuthUsersResetPasswordCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<SendEmailReset>) => r.body as SendEmailReset)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetPasswordCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResetPasswordCreate$FormData$Response(params: {
    body: SendEmailReset
  }): Observable<StrictHttpResponse<SendEmailReset>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetPasswordCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendEmailReset>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetPasswordCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResetPasswordCreate$FormData(params: {
    body: SendEmailReset
  }): Observable<SendEmailReset> {

    return this.apiAuthUsersResetPasswordCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<SendEmailReset>) => r.body as SendEmailReset)
    );
  }

  /**
   * Path part for operation apiAuthUsersResetPasswordConfirmCreate
   */
  static readonly ApiAuthUsersResetPasswordConfirmCreatePath = '/api/auth/users/reset_password_confirm/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetPasswordConfirmCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResetPasswordConfirmCreate$Json$Response(params: {
    body: PasswordResetConfirm
  }): Observable<StrictHttpResponse<PasswordResetConfirm>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetPasswordConfirmCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PasswordResetConfirm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetPasswordConfirmCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersResetPasswordConfirmCreate$Json(params: {
    body: PasswordResetConfirm
  }): Observable<PasswordResetConfirm> {

    return this.apiAuthUsersResetPasswordConfirmCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PasswordResetConfirm>) => r.body as PasswordResetConfirm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetPasswordConfirmCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResetPasswordConfirmCreate$XWwwFormUrlencoded$Response(params: {
    body: PasswordResetConfirm
  }): Observable<StrictHttpResponse<PasswordResetConfirm>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetPasswordConfirmCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PasswordResetConfirm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetPasswordConfirmCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersResetPasswordConfirmCreate$XWwwFormUrlencoded(params: {
    body: PasswordResetConfirm
  }): Observable<PasswordResetConfirm> {

    return this.apiAuthUsersResetPasswordConfirmCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<PasswordResetConfirm>) => r.body as PasswordResetConfirm)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersResetPasswordConfirmCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResetPasswordConfirmCreate$FormData$Response(params: {
    body: PasswordResetConfirm
  }): Observable<StrictHttpResponse<PasswordResetConfirm>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersResetPasswordConfirmCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PasswordResetConfirm>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersResetPasswordConfirmCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersResetPasswordConfirmCreate$FormData(params: {
    body: PasswordResetConfirm
  }): Observable<PasswordResetConfirm> {

    return this.apiAuthUsersResetPasswordConfirmCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<PasswordResetConfirm>) => r.body as PasswordResetConfirm)
    );
  }

  /**
   * Path part for operation apiAuthUsersSetEmailCreate
   */
  static readonly ApiAuthUsersSetEmailCreatePath = '/api/auth/users/set_email/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersSetEmailCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersSetEmailCreate$Json$Response(params: {
    body: SetUsername
  }): Observable<StrictHttpResponse<SetUsername>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersSetEmailCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetUsername>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersSetEmailCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersSetEmailCreate$Json(params: {
    body: SetUsername
  }): Observable<SetUsername> {

    return this.apiAuthUsersSetEmailCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SetUsername>) => r.body as SetUsername)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersSetEmailCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersSetEmailCreate$XWwwFormUrlencoded$Response(params: {
    body: SetUsername
  }): Observable<StrictHttpResponse<SetUsername>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersSetEmailCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetUsername>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersSetEmailCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersSetEmailCreate$XWwwFormUrlencoded(params: {
    body: SetUsername
  }): Observable<SetUsername> {

    return this.apiAuthUsersSetEmailCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<SetUsername>) => r.body as SetUsername)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersSetEmailCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersSetEmailCreate$FormData$Response(params: {
    body: SetUsername
  }): Observable<StrictHttpResponse<SetUsername>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersSetEmailCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetUsername>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersSetEmailCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersSetEmailCreate$FormData(params: {
    body: SetUsername
  }): Observable<SetUsername> {

    return this.apiAuthUsersSetEmailCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<SetUsername>) => r.body as SetUsername)
    );
  }

  /**
   * Path part for operation apiAuthUsersSetPasswordCreate
   */
  static readonly ApiAuthUsersSetPasswordCreatePath = '/api/auth/users/set_password/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersSetPasswordCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersSetPasswordCreate$Json$Response(params: {
    body: SetPassword
  }): Observable<StrictHttpResponse<SetPassword>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersSetPasswordCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetPassword>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersSetPasswordCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuthUsersSetPasswordCreate$Json(params: {
    body: SetPassword
  }): Observable<SetPassword> {

    return this.apiAuthUsersSetPasswordCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SetPassword>) => r.body as SetPassword)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersSetPasswordCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersSetPasswordCreate$XWwwFormUrlencoded$Response(params: {
    body: SetPassword
  }): Observable<StrictHttpResponse<SetPassword>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersSetPasswordCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetPassword>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersSetPasswordCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiAuthUsersSetPasswordCreate$XWwwFormUrlencoded(params: {
    body: SetPassword
  }): Observable<SetPassword> {

    return this.apiAuthUsersSetPasswordCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<SetPassword>) => r.body as SetPassword)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuthUsersSetPasswordCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersSetPasswordCreate$FormData$Response(params: {
    body: SetPassword
  }): Observable<StrictHttpResponse<SetPassword>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuthUsersSetPasswordCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetPassword>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAuthUsersSetPasswordCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiAuthUsersSetPasswordCreate$FormData(params: {
    body: SetPassword
  }): Observable<SetPassword> {

    return this.apiAuthUsersSetPasswordCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<SetPassword>) => r.body as SetPassword)
    );
  }

  /**
   * Path part for operation apiEventsList
   */
  static readonly ApiEventsListPath = '/api/events/';

  /**
   * Returns the list of existing events. For normal users this returns default only active or running events, for admin users also draft events are shown
   *
   * STATE_DRAFT = 0
   * STATE_ACTIVE = 1
   * STATE_RUNNING = 2
   * STATE_ARCHIVED = 3
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsList$Response(params?: {
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    name?: string;
    name__icontains?: string;
    name__istartswith?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-id' | '-name' | '-state' | 'id' | 'name' | 'state'>;
    state?: 0 | 1 | 2 | 3;
  }): Observable<StrictHttpResponse<PaginatedEventReadonlyList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiEventsListPath, 'get');
    if (params) {
      rb.query('created', params.created, {});
      rb.query('created__date', params.created__date, {});
      rb.query('created__date__gte', params.created__date__gte, {});
      rb.query('created__date__lte', params.created__date__lte, {});
      rb.query('created__date__range', params.created__date__range, {"style":"form","explode":false});
      rb.query('created__gt', params.created__gt, {});
      rb.query('created__gte', params.created__gte, {});
      rb.query('created__isnull', params.created__isnull, {});
      rb.query('created__lt', params.created__lt, {});
      rb.query('created__lte', params.created__lte, {});
      rb.query('created__range', params.created__range, {"style":"form","explode":false});
      rb.query('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('name', params.name, {});
      rb.query('name__icontains', params.name__icontains, {});
      rb.query('name__istartswith', params.name__istartswith, {});
      rb.query('offset', params.offset, {});
      rb.query('orderby', params.orderby, {"style":"form","explode":false});
      rb.query('state', params.state, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedEventReadonlyList>;
      })
    );
  }

  /**
   * Returns the list of existing events. For normal users this returns default only active or running events, for admin users also draft events are shown
   *
   * STATE_DRAFT = 0
   * STATE_ACTIVE = 1
   * STATE_RUNNING = 2
   * STATE_ARCHIVED = 3
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEventsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsList(params?: {
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    name?: string;
    name__icontains?: string;
    name__istartswith?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-id' | '-name' | '-state' | 'id' | 'name' | 'state'>;
    state?: 0 | 1 | 2 | 3;
  }): Observable<PaginatedEventReadonlyList> {

    return this.apiEventsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedEventReadonlyList>) => r.body as PaginatedEventReadonlyList)
    );
  }

  /**
   * Path part for operation apiEventsRetrieve
   */
  static readonly ApiEventsRetrievePath = '/api/events/{id}/';

  /**
   * detail of a Event
   *
   * STATE_DRAFT = 0
   * STATE_ACTIVE = 1
   * STATE_RUNNING = 2
   * STATE_ARCHIVED = 3
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsRetrieve$Response(params: {

    /**
     * A UUID string identifying this Veranstaltung.
     */
    id: string;
  }): Observable<StrictHttpResponse<EventReadonly>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiEventsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventReadonly>;
      })
    );
  }

  /**
   * detail of a Event
   *
   * STATE_DRAFT = 0
   * STATE_ACTIVE = 1
   * STATE_RUNNING = 2
   * STATE_ARCHIVED = 3
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEventsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsRetrieve(params: {

    /**
     * A UUID string identifying this Veranstaltung.
     */
    id: string;
  }): Observable<EventReadonly> {

    return this.apiEventsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<EventReadonly>) => r.body as EventReadonly)
    );
  }

  /**
   * Path part for operation apiEventsSubscribeRetrieve
   */
  static readonly ApiEventsSubscribeRetrievePath = '/api/events/{id}/subscribe';

  /**
   * Subscribe or unsubscribe to an Event with the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventsSubscribeRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsSubscribeRetrieve$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiEventsSubscribeRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Subscribe or unsubscribe to an Event with the current user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEventsSubscribeRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsSubscribeRetrieve(params: {
    id: string;
  }): Observable<void> {

    return this.apiEventsSubscribeRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiEventsSubscribeCreate
   */
  static readonly ApiEventsSubscribeCreatePath = '/api/events/{id}/subscribe';

  /**
   * Subscribe or unsubscribe to an Event with the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventsSubscribeCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsSubscribeCreate$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiEventsSubscribeCreatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Subscribe or unsubscribe to an Event with the current user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEventsSubscribeCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsSubscribeCreate(params: {
    id: string;
  }): Observable<void> {

    return this.apiEventsSubscribeCreate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiEventsSubscribeDestroy
   */
  static readonly ApiEventsSubscribeDestroyPath = '/api/events/{id}/subscribe';

  /**
   * Subscribe or unsubscribe to an Event with the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEventsSubscribeDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsSubscribeDestroy$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiEventsSubscribeDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Subscribe or unsubscribe to an Event with the current user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEventsSubscribeDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEventsSubscribeDestroy(params: {
    id: string;
  }): Observable<void> {

    return this.apiEventsSubscribeDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiExhibitordataList
   */
  static readonly ApiExhibitordataListPath = '/api/exhibitordata/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExhibitordataList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExhibitordataList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedExhibitorDataList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiExhibitordataListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedExhibitorDataList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExhibitordataList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExhibitordataList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedExhibitorDataList> {

    return this.apiExhibitordataList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedExhibitorDataList>) => r.body as PaginatedExhibitorDataList)
    );
  }

  /**
   * Path part for operation apiExhibitordataCreate
   */
  static readonly ApiExhibitordataCreatePath = '/api/exhibitordata/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExhibitordataCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiExhibitordataCreate$Json$Response(params: {
    body: ExhibitorData
  }): Observable<StrictHttpResponse<ExhibitorData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiExhibitordataCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExhibitorData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExhibitordataCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiExhibitordataCreate$Json(params: {
    body: ExhibitorData
  }): Observable<ExhibitorData> {

    return this.apiExhibitordataCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExhibitorData>) => r.body as ExhibitorData)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExhibitordataCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiExhibitordataCreate$XWwwFormUrlencoded$Response(params: {
    body: ExhibitorData
  }): Observable<StrictHttpResponse<ExhibitorData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiExhibitordataCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExhibitorData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExhibitordataCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiExhibitordataCreate$XWwwFormUrlencoded(params: {
    body: ExhibitorData
  }): Observable<ExhibitorData> {

    return this.apiExhibitordataCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<ExhibitorData>) => r.body as ExhibitorData)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExhibitordataCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiExhibitordataCreate$FormData$Response(params: {
    body: ExhibitorData
  }): Observable<StrictHttpResponse<ExhibitorData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiExhibitordataCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExhibitorData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExhibitordataCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiExhibitordataCreate$FormData(params: {
    body: ExhibitorData
  }): Observable<ExhibitorData> {

    return this.apiExhibitordataCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<ExhibitorData>) => r.body as ExhibitorData)
    );
  }

  /**
   * Path part for operation apiExhibitorsList
   */
  static readonly ApiExhibitorsListPath = '/api/exhibitors/';

  /**
   * Returns the list of existing exhibitors
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExhibitorsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExhibitorsList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedExhibitorShortList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiExhibitorsListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedExhibitorShortList>;
      })
    );
  }

  /**
   * Returns the list of existing exhibitors
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExhibitorsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExhibitorsList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedExhibitorShortList> {

    return this.apiExhibitorsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedExhibitorShortList>) => r.body as PaginatedExhibitorShortList)
    );
  }

  /**
   * Path part for operation apiExhibitorsRetrieve
   */
  static readonly ApiExhibitorsRetrievePath = '/api/exhibitors/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExhibitorsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExhibitorsRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Aussteller.
     */
    id: number;
  }): Observable<StrictHttpResponse<ExhibitorReadonly>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiExhibitorsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExhibitorReadonly>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExhibitorsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExhibitorsRetrieve(params: {

    /**
     * A unique integer value identifying this Aussteller.
     */
    id: number;
  }): Observable<ExhibitorReadonly> {

    return this.apiExhibitorsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<ExhibitorReadonly>) => r.body as ExhibitorReadonly)
    );
  }

  /**
   * Path part for operation apiLecturerList
   */
  static readonly ApiLecturerListPath = '/api/lecturer/';

  /**
   * Returns the list of lecturers for all subscribed events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedLecturerReadOnlyList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedLecturerReadOnlyList>;
      })
    );
  }

  /**
   * Returns the list of lecturers for all subscribed events
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedLecturerReadOnlyList> {

    return this.apiLecturerList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedLecturerReadOnlyList>) => r.body as PaginatedLecturerReadOnlyList)
    );
  }

  /**
   * Path part for operation apiLecturerBillingfeeRetrieve
   */
  static readonly ApiLecturerBillingfeeRetrievePath = '/api/lecturer-billingfee/{id}/';

  /**
   * Render the lecturer confirmation as PDF
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerBillingfeeRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerBillingfeeRetrieve$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerBillingfeeRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Render the lecturer confirmation as PDF
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerBillingfeeRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerBillingfeeRetrieve(params: {
    id: string;
  }): Observable<Blob> {

    return this.apiLecturerBillingfeeRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiLecturerConfirmationList
   */
  static readonly ApiLecturerConfirmationListPath = '/api/lecturer-confirmation/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerConfirmationList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerConfirmationList$Response(params?: {
    event?: string;
    id?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedLecturerConfirmationList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerConfirmationListPath, 'get');
    if (params) {
      rb.query('event', params.event, {});
      rb.query('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedLecturerConfirmationList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerConfirmationList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerConfirmationList(params?: {
    event?: string;
    id?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedLecturerConfirmationList> {

    return this.apiLecturerConfirmationList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedLecturerConfirmationList>) => r.body as PaginatedLecturerConfirmationList)
    );
  }

  /**
   * Path part for operation apiLecturerConfirmationRetrieve
   */
  static readonly ApiLecturerConfirmationRetrievePath = '/api/lecturer-confirmation/{id}/';

  /**
   * Render the lecturer confirmation as PDF
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerConfirmationRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerConfirmationRetrieve$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerConfirmationRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Render the lecturer confirmation as PDF
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerConfirmationRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerConfirmationRetrieve(params: {
    id: string;
  }): Observable<Blob> {

    return this.apiLecturerConfirmationRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiLecturerDataList
   */
  static readonly ApiLecturerDataListPath = '/api/lecturer-data/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerDataList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerDataList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedLecturerDataList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerDataListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedLecturerDataList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerDataList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerDataList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedLecturerDataList> {

    return this.apiLecturerDataList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedLecturerDataList>) => r.body as PaginatedLecturerDataList)
    );
  }

  /**
   * Path part for operation apiLecturerDataRetrieve
   */
  static readonly ApiLecturerDataRetrievePath = '/api/lecturer-data/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerDataRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerDataRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
  }): Observable<StrictHttpResponse<LecturerData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerDataRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LecturerData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerDataRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerDataRetrieve(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
  }): Observable<LecturerData> {

    return this.apiLecturerDataRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<LecturerData>) => r.body as LecturerData)
    );
  }

  /**
   * Path part for operation apiLecturerDataUpdate
   */
  static readonly ApiLecturerDataUpdatePath = '/api/lecturer-data/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerDataUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerDataUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body: LecturerData
  }): Observable<StrictHttpResponse<LecturerData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerDataUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LecturerData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerDataUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerDataUpdate$Json(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body: LecturerData
  }): Observable<LecturerData> {

    return this.apiLecturerDataUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LecturerData>) => r.body as LecturerData)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerDataUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerDataUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body: LecturerData
  }): Observable<StrictHttpResponse<LecturerData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerDataUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LecturerData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerDataUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerDataUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body: LecturerData
  }): Observable<LecturerData> {

    return this.apiLecturerDataUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<LecturerData>) => r.body as LecturerData)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerDataUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerDataUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body: LecturerData
  }): Observable<StrictHttpResponse<LecturerData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerDataUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LecturerData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerDataUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerDataUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body: LecturerData
  }): Observable<LecturerData> {

    return this.apiLecturerDataUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<LecturerData>) => r.body as LecturerData)
    );
  }

  /**
   * Path part for operation apiLecturerDataPartialUpdate
   */
  static readonly ApiLecturerDataPartialUpdatePath = '/api/lecturer-data/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerDataPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerDataPartialUpdate$Json$Response(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body?: PatchedLecturerData
  }): Observable<StrictHttpResponse<LecturerData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerDataPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LecturerData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerDataPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerDataPartialUpdate$Json(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body?: PatchedLecturerData
  }): Observable<LecturerData> {

    return this.apiLecturerDataPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LecturerData>) => r.body as LecturerData)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerDataPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerDataPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body?: PatchedLecturerData
  }): Observable<StrictHttpResponse<LecturerData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerDataPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LecturerData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerDataPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerDataPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body?: PatchedLecturerData
  }): Observable<LecturerData> {

    return this.apiLecturerDataPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<LecturerData>) => r.body as LecturerData)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerDataPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerDataPartialUpdate$FormData$Response(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body?: PatchedLecturerData
  }): Observable<StrictHttpResponse<LecturerData>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerDataPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LecturerData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerDataPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerDataPartialUpdate$FormData(params: {

    /**
     * A unique integer value identifying this Referent Veranstaltungdaten.
     */
    id: number;
    body?: PatchedLecturerData
  }): Observable<LecturerData> {

    return this.apiLecturerDataPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<LecturerData>) => r.body as LecturerData)
    );
  }

  /**
   * Path part for operation apiLecturerParticipantsList
   */
  static readonly ApiLecturerParticipantsListPath = '/api/lecturer-participants/';

  /**
   * Returns a list of participants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerParticipantsList$Response(params?: {
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    state?: 0 | 1 | 2 | 3;
    timeslot?: string;
  }): Observable<StrictHttpResponse<PaginatedTimeslotParticipationPresenceList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsListPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('state', params.state, {});
      rb.query('timeslot', params.timeslot, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedTimeslotParticipationPresenceList>;
      })
    );
  }

  /**
   * Returns a list of participants
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerParticipantsList(params?: {
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
    state?: 0 | 1 | 2 | 3;
    timeslot?: string;
  }): Observable<PaginatedTimeslotParticipationPresenceList> {

    return this.apiLecturerParticipantsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedTimeslotParticipationPresenceList>) => r.body as PaginatedTimeslotParticipationPresenceList)
    );
  }

  /**
   * Path part for operation apiLecturerParticipantsCreate
   */
  static readonly ApiLecturerParticipantsCreatePath = '/api/lecturer-participants/';

  /**
   * Returns a list of participants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerParticipantsCreate$Json$Response(params: {
    body: TimeslotParticipationPresence
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a list of participants
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerParticipantsCreate$Json(params: {
    body: TimeslotParticipationPresence
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Returns a list of participants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerParticipantsCreate$XWwwFormUrlencoded$Response(params: {
    body: TimeslotParticipationPresence
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a list of participants
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerParticipantsCreate$XWwwFormUrlencoded(params: {
    body: TimeslotParticipationPresence
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Returns a list of participants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerParticipantsCreate$FormData$Response(params: {
    body: TimeslotParticipationPresence
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a list of participants
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerParticipantsCreate$FormData(params: {
    body: TimeslotParticipationPresence
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Path part for operation apiLecturerParticipantsRetrieve
   */
  static readonly ApiLecturerParticipantsRetrievePath = '/api/lecturer-participants/{id}';

  /**
   * Returns a participant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerParticipantsRetrieve$Response(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerParticipantsRetrieve(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Path part for operation apiLecturerParticipantsUpdate
   */
  static readonly ApiLecturerParticipantsUpdatePath = '/api/lecturer-participants/{id}';

  /**
   * Returns a participant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerParticipantsUpdate$Json$Response(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body: TimeslotParticipationPresence
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerParticipantsUpdate$Json(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body: TimeslotParticipationPresence
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerParticipantsUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body: TimeslotParticipationPresence
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerParticipantsUpdate$XWwwFormUrlencoded(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body: TimeslotParticipationPresence
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerParticipantsUpdate$FormData$Response(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body: TimeslotParticipationPresence
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsUpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerParticipantsUpdate$FormData(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body: TimeslotParticipationPresence
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Path part for operation apiLecturerParticipantsPartialUpdate
   */
  static readonly ApiLecturerParticipantsPartialUpdatePath = '/api/lecturer-participants/{id}';

  /**
   * Returns a participant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerParticipantsPartialUpdate$Json$Response(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body?: PatchedTimeslotParticipationPresence
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLecturerParticipantsPartialUpdate$Json(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body?: PatchedTimeslotParticipationPresence
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerParticipantsPartialUpdate$XWwwFormUrlencoded$Response(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body?: PatchedTimeslotParticipationPresence
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiLecturerParticipantsPartialUpdate$XWwwFormUrlencoded(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body?: PatchedTimeslotParticipationPresence
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsPartialUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerParticipantsPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerParticipantsPartialUpdate$FormData$Response(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body?: PatchedTimeslotParticipationPresence
  }): Observable<StrictHttpResponse<TimeslotParticipationPresence>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerParticipantsPartialUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotParticipationPresence>;
      })
    );
  }

  /**
   * Returns a participant
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerParticipantsPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLecturerParticipantsPartialUpdate$FormData(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
    body?: PatchedTimeslotParticipationPresence
  }): Observable<TimeslotParticipationPresence> {

    return this.apiLecturerParticipantsPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotParticipationPresence>) => r.body as TimeslotParticipationPresence)
    );
  }

  /**
   * Path part for operation apiLecturerTimeslotsList
   */
  static readonly ApiLecturerTimeslotsListPath = '/api/lecturer-timeslots/';

  /**
   * Returns a list of the lecturers workshops. Only for running events timeslots are shown
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerTimeslotsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerTimeslotsList$Response(params?: {
    caption?: string;
    caption__icontains?: string;
    caption__istartswith?: string;
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    end?: string;
    end__date?: string;
    end__date__gte?: string;
    end__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__date__range?: Array<string>;
    end__gt?: string;
    end__gte?: string;
    end__isnull?: string;
    end__lt?: string;
    end__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__range?: Array<string>;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-end' | '-id' | '-name' | '-start' | 'end' | 'id' | 'name' | 'start'>;
    start?: string;
    start__date?: string;
    start__date__gte?: string;
    start__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__date__range?: Array<string>;
    start__gt?: string;
    start__gte?: string;
    start__isnull?: string;
    start__lt?: string;
    start__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__range?: Array<string>;
  }): Observable<StrictHttpResponse<PaginatedTimeslotReadonlyList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerTimeslotsListPath, 'get');
    if (params) {
      rb.query('caption', params.caption, {});
      rb.query('caption__icontains', params.caption__icontains, {});
      rb.query('caption__istartswith', params.caption__istartswith, {});
      rb.query('created', params.created, {});
      rb.query('created__date', params.created__date, {});
      rb.query('created__date__gte', params.created__date__gte, {});
      rb.query('created__date__lte', params.created__date__lte, {});
      rb.query('created__date__range', params.created__date__range, {"style":"form","explode":false});
      rb.query('created__gt', params.created__gt, {});
      rb.query('created__gte', params.created__gte, {});
      rb.query('created__isnull', params.created__isnull, {});
      rb.query('created__lt', params.created__lt, {});
      rb.query('created__lte', params.created__lte, {});
      rb.query('created__range', params.created__range, {"style":"form","explode":false});
      rb.query('end', params.end, {});
      rb.query('end__date', params.end__date, {});
      rb.query('end__date__gte', params.end__date__gte, {});
      rb.query('end__date__lte', params.end__date__lte, {});
      rb.query('end__date__range', params.end__date__range, {"style":"form","explode":false});
      rb.query('end__gt', params.end__gt, {});
      rb.query('end__gte', params.end__gte, {});
      rb.query('end__isnull', params.end__isnull, {});
      rb.query('end__lt', params.end__lt, {});
      rb.query('end__lte', params.end__lte, {});
      rb.query('end__range', params.end__range, {"style":"form","explode":false});
      rb.query('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('orderby', params.orderby, {"style":"form","explode":false});
      rb.query('start', params.start, {});
      rb.query('start__date', params.start__date, {});
      rb.query('start__date__gte', params.start__date__gte, {});
      rb.query('start__date__lte', params.start__date__lte, {});
      rb.query('start__date__range', params.start__date__range, {"style":"form","explode":false});
      rb.query('start__gt', params.start__gt, {});
      rb.query('start__gte', params.start__gte, {});
      rb.query('start__isnull', params.start__isnull, {});
      rb.query('start__lt', params.start__lt, {});
      rb.query('start__lte', params.start__lte, {});
      rb.query('start__range', params.start__range, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedTimeslotReadonlyList>;
      })
    );
  }

  /**
   * Returns a list of the lecturers workshops. Only for running events timeslots are shown
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerTimeslotsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerTimeslotsList(params?: {
    caption?: string;
    caption__icontains?: string;
    caption__istartswith?: string;
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    end?: string;
    end__date?: string;
    end__date__gte?: string;
    end__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__date__range?: Array<string>;
    end__gt?: string;
    end__gte?: string;
    end__isnull?: string;
    end__lt?: string;
    end__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__range?: Array<string>;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-end' | '-id' | '-name' | '-start' | 'end' | 'id' | 'name' | 'start'>;
    start?: string;
    start__date?: string;
    start__date__gte?: string;
    start__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__date__range?: Array<string>;
    start__gt?: string;
    start__gte?: string;
    start__isnull?: string;
    start__lt?: string;
    start__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__range?: Array<string>;
  }): Observable<PaginatedTimeslotReadonlyList> {

    return this.apiLecturerTimeslotsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedTimeslotReadonlyList>) => r.body as PaginatedTimeslotReadonlyList)
    );
  }

  /**
   * Path part for operation apiLecturerTimeslotsRetrieve
   */
  static readonly ApiLecturerTimeslotsRetrievePath = '/api/lecturer-timeslots/{id}/';

  /**
   * Returns a list of the lecturers workshops. Only for running events timeslots are shown
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerTimeslotsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerTimeslotsRetrieve$Response(params: {

    /**
     * A UUID string identifying this Workshop Timeslot.
     */
    id: string;
  }): Observable<StrictHttpResponse<TimeslotReadonly>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerTimeslotsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotReadonly>;
      })
    );
  }

  /**
   * Returns a list of the lecturers workshops. Only for running events timeslots are shown
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerTimeslotsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerTimeslotsRetrieve(params: {

    /**
     * A UUID string identifying this Workshop Timeslot.
     */
    id: string;
  }): Observable<TimeslotReadonly> {

    return this.apiLecturerTimeslotsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotReadonly>) => r.body as TimeslotReadonly)
    );
  }

  /**
   * Path part for operation apiLecturerRetrieve
   */
  static readonly ApiLecturerRetrievePath = '/api/lecturer/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLecturerRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerRetrieve$Response(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
  }): Observable<StrictHttpResponse<LecturerReadOnly>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLecturerRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LecturerReadOnly>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLecturerRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLecturerRetrieve(params: {

    /**
     * A unique integer value identifying this Account.
     */
    id: number;
  }): Observable<LecturerReadOnly> {

    return this.apiLecturerRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<LecturerReadOnly>) => r.body as LecturerReadOnly)
    );
  }

  /**
   * Path part for operation apiPagesRetrieve
   */
  static readonly ApiPagesRetrievePath = '/api/pages/{name}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPagesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPagesRetrieve$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<EventeroPage>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiPagesRetrievePath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventeroPage>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPagesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPagesRetrieve(params: {
    name: string;
  }): Observable<EventeroPage> {

    return this.apiPagesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<EventeroPage>) => r.body as EventeroPage)
    );
  }

  /**
   * Path part for operation apiSchemaRetrieve
   */
  static readonly ApiSchemaRetrievePath = '/api/schema/';

  /**
   * OpenApi3 schema for this API. Format can be selected via content negotiation.
   *
   * - YAML: application/vnd.oai.openapi
   * - JSON: application/vnd.oai.openapi+json
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchemaRetrieve$VndOaiOpenapi()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchemaRetrieve$VndOaiOpenapi$Response(params?: {
    format?: 'json' | 'yaml';
    lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant';
  }): Observable<StrictHttpResponse<{ [key: string]: any }>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiSchemaRetrievePath, 'get');
    if (params) {
      rb.query('format', params.format, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/vnd.oai.openapi'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: any }>;
      })
    );
  }

  /**
   * OpenApi3 schema for this API. Format can be selected via content negotiation.
   *
   * - YAML: application/vnd.oai.openapi
   * - JSON: application/vnd.oai.openapi+json
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchemaRetrieve$VndOaiOpenapi$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchemaRetrieve$VndOaiOpenapi(params?: {
    format?: 'json' | 'yaml';
    lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant';
  }): Observable<{ [key: string]: any }> {

    return this.apiSchemaRetrieve$VndOaiOpenapi$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: any }>) => r.body as { [key: string]: any })
    );
  }

  /**
   * OpenApi3 schema for this API. Format can be selected via content negotiation.
   *
   * - YAML: application/vnd.oai.openapi
   * - JSON: application/vnd.oai.openapi+json
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchemaRetrieve$Yaml()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchemaRetrieve$Yaml$Response(params?: {
    format?: 'json' | 'yaml';
    lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant';
  }): Observable<StrictHttpResponse<{ [key: string]: any }>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiSchemaRetrievePath, 'get');
    if (params) {
      rb.query('format', params.format, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/yaml'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: any }>;
      })
    );
  }

  /**
   * OpenApi3 schema for this API. Format can be selected via content negotiation.
   *
   * - YAML: application/vnd.oai.openapi
   * - JSON: application/vnd.oai.openapi+json
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchemaRetrieve$Yaml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchemaRetrieve$Yaml(params?: {
    format?: 'json' | 'yaml';
    lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant';
  }): Observable<{ [key: string]: any }> {

    return this.apiSchemaRetrieve$Yaml$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: any }>) => r.body as { [key: string]: any })
    );
  }

  /**
   * OpenApi3 schema for this API. Format can be selected via content negotiation.
   *
   * - YAML: application/vnd.oai.openapi
   * - JSON: application/vnd.oai.openapi+json
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchemaRetrieve$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchemaRetrieve$Json$Response(params?: {
    format?: 'json' | 'yaml';
    lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant';
  }): Observable<StrictHttpResponse<{ [key: string]: any }>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiSchemaRetrievePath, 'get');
    if (params) {
      rb.query('format', params.format, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: any }>;
      })
    );
  }

  /**
   * OpenApi3 schema for this API. Format can be selected via content negotiation.
   *
   * - YAML: application/vnd.oai.openapi
   * - JSON: application/vnd.oai.openapi+json
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchemaRetrieve$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchemaRetrieve$Json(params?: {
    format?: 'json' | 'yaml';
    lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant';
  }): Observable<{ [key: string]: any }> {

    return this.apiSchemaRetrieve$Json$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: any }>) => r.body as { [key: string]: any })
    );
  }

  /**
   * Path part for operation apiSchooltypesList
   */
  static readonly ApiSchooltypesListPath = '/api/schooltypes/';

  /**
   * Returns a list of the lecturers workshops. Only for running events timeslots are shown
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchooltypesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchooltypesList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedSchoolTypeList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiSchooltypesListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedSchoolTypeList>;
      })
    );
  }

  /**
   * Returns a list of the lecturers workshops. Only for running events timeslots are shown
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchooltypesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchooltypesList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedSchoolTypeList> {

    return this.apiSchooltypesList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedSchoolTypeList>) => r.body as PaginatedSchoolTypeList)
    );
  }

  /**
   * Path part for operation apiSupportCreate
   */
  static readonly ApiSupportCreatePath = '/api/support/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSupportCreate$Json$Response(params: {
    body: SupportQuestion
  }): Observable<StrictHttpResponse<SupportQuestion>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiSupportCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupportQuestion>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupportCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSupportCreate$Json(params: {
    body: SupportQuestion
  }): Observable<SupportQuestion> {

    return this.apiSupportCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupportQuestion>) => r.body as SupportQuestion)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiSupportCreate$XWwwFormUrlencoded$Response(params: {
    body: SupportQuestion
  }): Observable<StrictHttpResponse<SupportQuestion>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiSupportCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupportQuestion>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupportCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiSupportCreate$XWwwFormUrlencoded(params: {
    body: SupportQuestion
  }): Observable<SupportQuestion> {

    return this.apiSupportCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<SupportQuestion>) => r.body as SupportQuestion)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSupportCreate$FormData$Response(params: {
    body: SupportQuestion
  }): Observable<StrictHttpResponse<SupportQuestion>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiSupportCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupportQuestion>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSupportCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiSupportCreate$FormData(params: {
    body: SupportQuestion
  }): Observable<SupportQuestion> {

    return this.apiSupportCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<SupportQuestion>) => r.body as SupportQuestion)
    );
  }

  /**
   * Path part for operation apiTimeslotsList
   */
  static readonly ApiTimeslotsListPath = '/api/timeslots/';

  /**
   * Returns the list of available workshop timeslots for all subscribed events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTimeslotsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsList$Response(params?: {
    caption?: string;
    caption__icontains?: string;
    caption__istartswith?: string;
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    end?: string;
    end__date?: string;
    end__date__gte?: string;
    end__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__date__range?: Array<string>;
    end__gt?: string;
    end__gte?: string;
    end__isnull?: string;
    end__lt?: string;
    end__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__range?: Array<string>;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-end' | '-id' | '-name' | '-start' | 'end' | 'id' | 'name' | 'start'>;
    start?: string;
    start__date?: string;
    start__date__gte?: string;
    start__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__date__range?: Array<string>;
    start__gt?: string;
    start__gte?: string;
    start__isnull?: string;
    start__lt?: string;
    start__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__range?: Array<string>;
  }): Observable<StrictHttpResponse<PaginatedTimeslotReadonlyList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTimeslotsListPath, 'get');
    if (params) {
      rb.query('caption', params.caption, {});
      rb.query('caption__icontains', params.caption__icontains, {});
      rb.query('caption__istartswith', params.caption__istartswith, {});
      rb.query('created', params.created, {});
      rb.query('created__date', params.created__date, {});
      rb.query('created__date__gte', params.created__date__gte, {});
      rb.query('created__date__lte', params.created__date__lte, {});
      rb.query('created__date__range', params.created__date__range, {"style":"form","explode":false});
      rb.query('created__gt', params.created__gt, {});
      rb.query('created__gte', params.created__gte, {});
      rb.query('created__isnull', params.created__isnull, {});
      rb.query('created__lt', params.created__lt, {});
      rb.query('created__lte', params.created__lte, {});
      rb.query('created__range', params.created__range, {"style":"form","explode":false});
      rb.query('end', params.end, {});
      rb.query('end__date', params.end__date, {});
      rb.query('end__date__gte', params.end__date__gte, {});
      rb.query('end__date__lte', params.end__date__lte, {});
      rb.query('end__date__range', params.end__date__range, {"style":"form","explode":false});
      rb.query('end__gt', params.end__gt, {});
      rb.query('end__gte', params.end__gte, {});
      rb.query('end__isnull', params.end__isnull, {});
      rb.query('end__lt', params.end__lt, {});
      rb.query('end__lte', params.end__lte, {});
      rb.query('end__range', params.end__range, {"style":"form","explode":false});
      rb.query('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('orderby', params.orderby, {"style":"form","explode":false});
      rb.query('start', params.start, {});
      rb.query('start__date', params.start__date, {});
      rb.query('start__date__gte', params.start__date__gte, {});
      rb.query('start__date__lte', params.start__date__lte, {});
      rb.query('start__date__range', params.start__date__range, {"style":"form","explode":false});
      rb.query('start__gt', params.start__gt, {});
      rb.query('start__gte', params.start__gte, {});
      rb.query('start__isnull', params.start__isnull, {});
      rb.query('start__lt', params.start__lt, {});
      rb.query('start__lte', params.start__lte, {});
      rb.query('start__range', params.start__range, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedTimeslotReadonlyList>;
      })
    );
  }

  /**
   * Returns the list of available workshop timeslots for all subscribed events
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTimeslotsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsList(params?: {
    caption?: string;
    caption__icontains?: string;
    caption__istartswith?: string;
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    end?: string;
    end__date?: string;
    end__date__gte?: string;
    end__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__date__range?: Array<string>;
    end__gt?: string;
    end__gte?: string;
    end__isnull?: string;
    end__lt?: string;
    end__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__range?: Array<string>;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-end' | '-id' | '-name' | '-start' | 'end' | 'id' | 'name' | 'start'>;
    start?: string;
    start__date?: string;
    start__date__gte?: string;
    start__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__date__range?: Array<string>;
    start__gt?: string;
    start__gte?: string;
    start__isnull?: string;
    start__lt?: string;
    start__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__range?: Array<string>;
  }): Observable<PaginatedTimeslotReadonlyList> {

    return this.apiTimeslotsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedTimeslotReadonlyList>) => r.body as PaginatedTimeslotReadonlyList)
    );
  }

  /**
   * Path part for operation apiTimeslotsRetrieve
   */
  static readonly ApiTimeslotsRetrievePath = '/api/timeslots/{id}/';

  /**
   * detail of a Timeslot
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTimeslotsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsRetrieve$Response(params: {

    /**
     * A UUID string identifying this Workshop Timeslot.
     */
    id: string;
  }): Observable<StrictHttpResponse<TimeslotDetail>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTimeslotsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimeslotDetail>;
      })
    );
  }

  /**
   * detail of a Timeslot
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTimeslotsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsRetrieve(params: {

    /**
     * A UUID string identifying this Workshop Timeslot.
     */
    id: string;
  }): Observable<TimeslotDetail> {

    return this.apiTimeslotsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<TimeslotDetail>) => r.body as TimeslotDetail)
    );
  }

  /**
   * Path part for operation apiTimeslotsSubscribeRetrieve
   */
  static readonly ApiTimeslotsSubscribeRetrievePath = '/api/timeslots/{id}/subscribe';

  /**
   * Get the current state of the participation
   *
   * STATE_SUBSCRIBED = 0
   * STATE_ABSENT = 1
   * STATE_PART = 2
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTimeslotsSubscribeRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsSubscribeRetrieve$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTimeslotsSubscribeRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get the current state of the participation
   *
   * STATE_SUBSCRIBED = 0
   * STATE_ABSENT = 1
   * STATE_PART = 2
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTimeslotsSubscribeRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsSubscribeRetrieve(params: {
    id: string;
  }): Observable<void> {

    return this.apiTimeslotsSubscribeRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTimeslotsSubscribeCreate
   */
  static readonly ApiTimeslotsSubscribeCreatePath = '/api/timeslots/{id}/subscribe';

  /**
   * Subscribe or unsubscribe to an (Workshop) Timeslot with the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTimeslotsSubscribeCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsSubscribeCreate$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTimeslotsSubscribeCreatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Subscribe or unsubscribe to an (Workshop) Timeslot with the current user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTimeslotsSubscribeCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsSubscribeCreate(params: {
    id: string;
  }): Observable<void> {

    return this.apiTimeslotsSubscribeCreate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTimeslotsSubscribeDestroy
   */
  static readonly ApiTimeslotsSubscribeDestroyPath = '/api/timeslots/{id}/subscribe';

  /**
   * Subscribe or unsubscribe to an (Workshop) Timeslot with the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTimeslotsSubscribeDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsSubscribeDestroy$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTimeslotsSubscribeDestroyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Subscribe or unsubscribe to an (Workshop) Timeslot with the current user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTimeslotsSubscribeDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTimeslotsSubscribeDestroy(params: {
    id: string;
  }): Observable<void> {

    return this.apiTimeslotsSubscribeDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTokenCreate
   */
  static readonly ApiTokenCreatePath = '/api/token/';

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTokenCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTokenCreate$Json$Response(params: {
    body: TokenObtainPair
  }): Observable<StrictHttpResponse<TokenObtainPair>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTokenCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenObtainPair>;
      })
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTokenCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTokenCreate$Json(params: {
    body: TokenObtainPair
  }): Observable<TokenObtainPair> {

    return this.apiTokenCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TokenObtainPair>) => r.body as TokenObtainPair)
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTokenCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiTokenCreate$XWwwFormUrlencoded$Response(params: {
    body: TokenObtainPair
  }): Observable<StrictHttpResponse<TokenObtainPair>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTokenCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenObtainPair>;
      })
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTokenCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiTokenCreate$XWwwFormUrlencoded(params: {
    body: TokenObtainPair
  }): Observable<TokenObtainPair> {

    return this.apiTokenCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<TokenObtainPair>) => r.body as TokenObtainPair)
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTokenCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTokenCreate$FormData$Response(params: {
    body: TokenObtainPair
  }): Observable<StrictHttpResponse<TokenObtainPair>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTokenCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenObtainPair>;
      })
    );
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTokenCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTokenCreate$FormData(params: {
    body: TokenObtainPair
  }): Observable<TokenObtainPair> {

    return this.apiTokenCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<TokenObtainPair>) => r.body as TokenObtainPair)
    );
  }

  /**
   * Path part for operation apiTokenRefreshCreate
   */
  static readonly ApiTokenRefreshCreatePath = '/api/token/refresh/';

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTokenRefreshCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTokenRefreshCreate$Json$Response(params: {
    body: TokenRefresh
  }): Observable<StrictHttpResponse<TokenRefresh>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTokenRefreshCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenRefresh>;
      })
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTokenRefreshCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTokenRefreshCreate$Json(params: {
    body: TokenRefresh
  }): Observable<TokenRefresh> {

    return this.apiTokenRefreshCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TokenRefresh>) => r.body as TokenRefresh)
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTokenRefreshCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiTokenRefreshCreate$XWwwFormUrlencoded$Response(params: {
    body: TokenRefresh
  }): Observable<StrictHttpResponse<TokenRefresh>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTokenRefreshCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenRefresh>;
      })
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTokenRefreshCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiTokenRefreshCreate$XWwwFormUrlencoded(params: {
    body: TokenRefresh
  }): Observable<TokenRefresh> {

    return this.apiTokenRefreshCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<TokenRefresh>) => r.body as TokenRefresh)
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTokenRefreshCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTokenRefreshCreate$FormData$Response(params: {
    body: TokenRefresh
  }): Observable<StrictHttpResponse<TokenRefresh>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTokenRefreshCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenRefresh>;
      })
    );
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiTokenRefreshCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTokenRefreshCreate$FormData(params: {
    body: TokenRefresh
  }): Observable<TokenRefresh> {

    return this.apiTokenRefreshCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<TokenRefresh>) => r.body as TokenRefresh)
    );
  }

  /**
   * Path part for operation apiUsersList
   */
  static readonly ApiUsersListPath = '/api/users/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersList$Response(params?: {
    as_customernumber?: string;
    as_customernumber__istartswith?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedLightAccountList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersListPath, 'get');
    if (params) {
      rb.query('as_customernumber', params.as_customernumber, {});
      rb.query('as_customernumber__istartswith', params.as_customernumber__istartswith, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedLightAccountList>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersList(params?: {
    as_customernumber?: string;
    as_customernumber__istartswith?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedLightAccountList> {

    return this.apiUsersList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedLightAccountList>) => r.body as PaginatedLightAccountList)
    );
  }

  /**
   * Path part for operation apiUsersParticipationConfirmationMailCreate
   */
  static readonly ApiUsersParticipationConfirmationMailCreatePath = '/api/users/participation-confirmation-mail/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersParticipationConfirmationMailCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersParticipationConfirmationMailCreate$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersParticipationConfirmationMailCreatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersParticipationConfirmationMailCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersParticipationConfirmationMailCreate(params: {
    id: string;
  }): Observable<void> {

    return this.apiUsersParticipationConfirmationMailCreate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersParticipationConfirmationsRetrieve
   */
  static readonly ApiUsersParticipationConfirmationsRetrievePath = '/api/users/participation-confirmations/{id}/';

  /**
   * Render the Participation confirmation as PDF
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersParticipationConfirmationsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersParticipationConfirmationsRetrieve$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersParticipationConfirmationsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Render the Participation confirmation as PDF
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersParticipationConfirmationsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersParticipationConfirmationsRetrieve(params: {
    id: string;
  }): Observable<Blob> {

    return this.apiUsersParticipationConfirmationsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiUsersParticipationsList
   */
  static readonly ApiUsersParticipationsListPath = '/api/users/participations/';

  /**
   * Returns the list of my successful participations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersParticipationsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersParticipationsList$Response(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<StrictHttpResponse<PaginatedParticipationInformationList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersParticipationsListPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedParticipationInformationList>;
      })
    );
  }

  /**
   * Returns the list of my successful participations
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersParticipationsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersParticipationsList(params?: {

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Observable<PaginatedParticipationInformationList> {

    return this.apiUsersParticipationsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedParticipationInformationList>) => r.body as PaginatedParticipationInformationList)
    );
  }

  /**
   * Path part for operation apiUsersParticipationsRetrieve
   */
  static readonly ApiUsersParticipationsRetrievePath = '/api/users/participations/{id}/';

  /**
   * Returns the list of my successful participations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersParticipationsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersParticipationsRetrieve$Response(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
  }): Observable<StrictHttpResponse<ParticipationInformation>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersParticipationsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ParticipationInformation>;
      })
    );
  }

  /**
   * Returns the list of my successful participations
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersParticipationsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersParticipationsRetrieve(params: {

    /**
     * A UUID string identifying this Workshop Teilnahme.
     */
    id: string;
  }): Observable<ParticipationInformation> {

    return this.apiUsersParticipationsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<ParticipationInformation>) => r.body as ParticipationInformation)
    );
  }

  /**
   * Path part for operation apiUsersParticipationsFeedbackCreate
   */
  static readonly ApiUsersParticipationsFeedbackCreatePath = '/api/users/participations/feedback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersParticipationsFeedbackCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiUsersParticipationsFeedbackCreate$Json$Response(params: {
    body: ParticipationSurvey
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersParticipationsFeedbackCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersParticipationsFeedbackCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiUsersParticipationsFeedbackCreate$Json(params: {
    body: ParticipationSurvey
  }): Observable<void> {

    return this.apiUsersParticipationsFeedbackCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersParticipationsFeedbackCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiUsersParticipationsFeedbackCreate$XWwwFormUrlencoded$Response(params: {
    body: ParticipationSurvey
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersParticipationsFeedbackCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersParticipationsFeedbackCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiUsersParticipationsFeedbackCreate$XWwwFormUrlencoded(params: {
    body: ParticipationSurvey
  }): Observable<void> {

    return this.apiUsersParticipationsFeedbackCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersParticipationsFeedbackCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiUsersParticipationsFeedbackCreate$FormData$Response(params: {
    body: ParticipationSurvey
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersParticipationsFeedbackCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersParticipationsFeedbackCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiUsersParticipationsFeedbackCreate$FormData(params: {
    body: ParticipationSurvey
  }): Observable<void> {

    return this.apiUsersParticipationsFeedbackCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersPictureUpdate
   */
  static readonly ApiUsersPictureUpdatePath = '/api/users/picture/';

  /**
   * see https://www.django-rest-framework.org/api-guide/parsers/#fileuploadparser
   * ng-openapi-gen will generate the correct api calls, however this does not work over Swagger UI
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersPictureUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiUsersPictureUpdate$FormData$Response(params: {
    body: AccountProfilePicture
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersPictureUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * see https://www.django-rest-framework.org/api-guide/parsers/#fileuploadparser
   * ng-openapi-gen will generate the correct api calls, however this does not work over Swagger UI
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersPictureUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiUsersPictureUpdate$FormData(params: {
    body: AccountProfilePicture
  }): Observable<Account> {

    return this.apiUsersPictureUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * see https://www.django-rest-framework.org/api-guide/parsers/#fileuploadparser
   * ng-openapi-gen will generate the correct api calls, however this does not work over Swagger UI
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersPictureUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiUsersPictureUpdate$XWwwFormUrlencoded$Response(params: {
    body: AccountProfilePicture
  }): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersPictureUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * see https://www.django-rest-framework.org/api-guide/parsers/#fileuploadparser
   * ng-openapi-gen will generate the correct api calls, however this does not work over Swagger UI
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersPictureUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiUsersPictureUpdate$XWwwFormUrlencoded(params: {
    body: AccountProfilePicture
  }): Observable<Account> {

    return this.apiUsersPictureUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation apiUsersQrRetrieve
   */
  static readonly ApiUsersQrRetrievePath = '/api/users/qr/';

  /**
   * Render the QR Code for a user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersQrRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersQrRetrieve$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersQrRetrievePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Render the QR Code for a user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersQrRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersQrRetrieve(params?: {
  }): Observable<void> {

    return this.apiUsersQrRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUsersTimeslotsList
   */
  static readonly ApiUsersTimeslotsListPath = '/api/users/timeslots/';

  /**
   * Returns the list of subscribed workshop timeslots
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUsersTimeslotsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersTimeslotsList$Response(params?: {
    caption?: string;
    caption__icontains?: string;
    caption__istartswith?: string;
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    end?: string;
    end__date?: string;
    end__date__gte?: string;
    end__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__date__range?: Array<string>;
    end__gt?: string;
    end__gte?: string;
    end__isnull?: string;
    end__lt?: string;
    end__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__range?: Array<string>;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-end' | '-id' | '-name' | '-start' | 'end' | 'id' | 'name' | 'start'>;
    start?: string;
    start__date?: string;
    start__date__gte?: string;
    start__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__date__range?: Array<string>;
    start__gt?: string;
    start__gte?: string;
    start__isnull?: string;
    start__lt?: string;
    start__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__range?: Array<string>;
  }): Observable<StrictHttpResponse<PaginatedTimeslotReadonlyList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUsersTimeslotsListPath, 'get');
    if (params) {
      rb.query('caption', params.caption, {});
      rb.query('caption__icontains', params.caption__icontains, {});
      rb.query('caption__istartswith', params.caption__istartswith, {});
      rb.query('created', params.created, {});
      rb.query('created__date', params.created__date, {});
      rb.query('created__date__gte', params.created__date__gte, {});
      rb.query('created__date__lte', params.created__date__lte, {});
      rb.query('created__date__range', params.created__date__range, {"style":"form","explode":false});
      rb.query('created__gt', params.created__gt, {});
      rb.query('created__gte', params.created__gte, {});
      rb.query('created__isnull', params.created__isnull, {});
      rb.query('created__lt', params.created__lt, {});
      rb.query('created__lte', params.created__lte, {});
      rb.query('created__range', params.created__range, {"style":"form","explode":false});
      rb.query('end', params.end, {});
      rb.query('end__date', params.end__date, {});
      rb.query('end__date__gte', params.end__date__gte, {});
      rb.query('end__date__lte', params.end__date__lte, {});
      rb.query('end__date__range', params.end__date__range, {"style":"form","explode":false});
      rb.query('end__gt', params.end__gt, {});
      rb.query('end__gte', params.end__gte, {});
      rb.query('end__isnull', params.end__isnull, {});
      rb.query('end__lt', params.end__lt, {});
      rb.query('end__lte', params.end__lte, {});
      rb.query('end__range', params.end__range, {"style":"form","explode":false});
      rb.query('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('orderby', params.orderby, {"style":"form","explode":false});
      rb.query('start', params.start, {});
      rb.query('start__date', params.start__date, {});
      rb.query('start__date__gte', params.start__date__gte, {});
      rb.query('start__date__lte', params.start__date__lte, {});
      rb.query('start__date__range', params.start__date__range, {"style":"form","explode":false});
      rb.query('start__gt', params.start__gt, {});
      rb.query('start__gte', params.start__gte, {});
      rb.query('start__isnull', params.start__isnull, {});
      rb.query('start__lt', params.start__lt, {});
      rb.query('start__lte', params.start__lte, {});
      rb.query('start__range', params.start__range, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedTimeslotReadonlyList>;
      })
    );
  }

  /**
   * Returns the list of subscribed workshop timeslots
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUsersTimeslotsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUsersTimeslotsList(params?: {
    caption?: string;
    caption__icontains?: string;
    caption__istartswith?: string;
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    end?: string;
    end__date?: string;
    end__date__gte?: string;
    end__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__date__range?: Array<string>;
    end__gt?: string;
    end__gte?: string;
    end__isnull?: string;
    end__lt?: string;
    end__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    end__range?: Array<string>;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-end' | '-id' | '-name' | '-start' | 'end' | 'id' | 'name' | 'start'>;
    start?: string;
    start__date?: string;
    start__date__gte?: string;
    start__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__date__range?: Array<string>;
    start__gt?: string;
    start__gte?: string;
    start__isnull?: string;
    start__lt?: string;
    start__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    start__range?: Array<string>;
  }): Observable<PaginatedTimeslotReadonlyList> {

    return this.apiUsersTimeslotsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedTimeslotReadonlyList>) => r.body as PaginatedTimeslotReadonlyList)
    );
  }

  /**
   * Path part for operation apiWorkshopsList
   */
  static readonly ApiWorkshopsListPath = '/api/workshops/';

  /**
   * Returns the list of existing events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkshopsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkshopsList$Response(params?: {
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    description__icontains?: string;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    location?: string;
    location__icontains?: string;
    location__istartswith?: string;
    name?: string;
    name__icontains?: string;
    name__istartswith?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-code' | '-id' | '-name' | 'code' | 'id' | 'name'>;
  }): Observable<StrictHttpResponse<PaginatedWorkshopReadonlyList>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiWorkshopsListPath, 'get');
    if (params) {
      rb.query('created', params.created, {});
      rb.query('created__date', params.created__date, {});
      rb.query('created__date__gte', params.created__date__gte, {});
      rb.query('created__date__lte', params.created__date__lte, {});
      rb.query('created__date__range', params.created__date__range, {"style":"form","explode":false});
      rb.query('created__gt', params.created__gt, {});
      rb.query('created__gte', params.created__gte, {});
      rb.query('created__isnull', params.created__isnull, {});
      rb.query('created__lt', params.created__lt, {});
      rb.query('created__lte', params.created__lte, {});
      rb.query('created__range', params.created__range, {"style":"form","explode":false});
      rb.query('description__icontains', params.description__icontains, {});
      rb.query('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('location', params.location, {});
      rb.query('location__icontains', params.location__icontains, {});
      rb.query('location__istartswith', params.location__istartswith, {});
      rb.query('name', params.name, {});
      rb.query('name__icontains', params.name__icontains, {});
      rb.query('name__istartswith', params.name__istartswith, {});
      rb.query('offset', params.offset, {});
      rb.query('orderby', params.orderby, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedWorkshopReadonlyList>;
      })
    );
  }

  /**
   * Returns the list of existing events
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkshopsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkshopsList(params?: {
    created?: string;
    created__date?: string;
    created__date__gte?: string;
    created__date__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__date__range?: Array<string>;
    created__gt?: string;
    created__gte?: string;
    created__isnull?: string;
    created__lt?: string;
    created__lte?: string;

    /**
     * Mehrere Werte können durch Kommas getrennt sein.
     */
    created__range?: Array<string>;
    description__icontains?: string;
    id?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;
    location?: string;
    location__icontains?: string;
    location__istartswith?: string;
    name?: string;
    name__icontains?: string;
    name__istartswith?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Sortierung
     */
    orderby?: Array<'-code' | '-id' | '-name' | 'code' | 'id' | 'name'>;
  }): Observable<PaginatedWorkshopReadonlyList> {

    return this.apiWorkshopsList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedWorkshopReadonlyList>) => r.body as PaginatedWorkshopReadonlyList)
    );
  }

  /**
   * Path part for operation apiWorkshopsRetrieve
   */
  static readonly ApiWorkshopsRetrievePath = '/api/workshops/{id}/';

  /**
   * detail of a Workshop
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkshopsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkshopsRetrieve$Response(params: {

    /**
     * A UUID string identifying this Workshop.
     */
    id: string;
  }): Observable<StrictHttpResponse<WorkshopReadonly>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiWorkshopsRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkshopReadonly>;
      })
    );
  }

  /**
   * detail of a Workshop
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkshopsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkshopsRetrieve(params: {

    /**
     * A UUID string identifying this Workshop.
     */
    id: string;
  }): Observable<WorkshopReadonly> {

    return this.apiWorkshopsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<WorkshopReadonly>) => r.body as WorkshopReadonly)
    );
  }

}
