import { Injectable } from '@angular/core';
import {ApiService} from '../api/services/api.service';


@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private apiService: ApiService) { }

  public getEventList() {
    return this.apiService.apiEventsList();
  }

  public getEventDetail(id) {
    return this.apiService.apiEventsRetrieve({id});
  }

  public subscribeToEvent(id) {
    return this.apiService.apiEventsSubscribeCreate({id});
  }

  public unSubscribeFromEvent(id) {
    return this.apiService.apiEventsSubscribeDestroy({id});
  }

}

