import {Injectable} from '@angular/core';
import {Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {UserService} from '../../services/user.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private user: UserService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.user
      .isLoggedIn$().pipe(
        map((e) => {
        if (e) {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      })
      )
  }
}
