import { NgModule } from '@angular/core';
import { QrCodeComponentComponent } from './qr-code-component/qr-code-component.component';
import { CountdownComponent } from './countdown/countdown.component';
import { CommonModule } from '@angular/common';
import {PasswordChangeComponent} from './password-change/password-change.component';
import {EmailChangeComponent} from './email-change/email-change.component';
import {ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ParticipantCountComponent} from './participant-count/participant-count.component';
import {DeskOrderComponent} from './desk-order/desk-order.component';
import {AccountDeleteComponent} from './account-delete/account-delete.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule
  ],
  declarations: [
    CountdownComponent,
    QrCodeComponentComponent,
    PasswordChangeComponent,
    EmailChangeComponent,
    ParticipantCountComponent,
    DeskOrderComponent,
    AccountDeleteComponent,
  ],
  exports: [
    CountdownComponent,
    QrCodeComponentComponent,
    PasswordChangeComponent,
    EmailChangeComponent,
    ParticipantCountComponent,
    DeskOrderComponent,
    AccountDeleteComponent,
  ],
  entryComponents: [
    QrCodeComponentComponent,
    PasswordChangeComponent,
    EmailChangeComponent,
    ParticipantCountComponent,
    DeskOrderComponent,
    AccountDeleteComponent,
  ]
})
export class ComponentsModule {}
