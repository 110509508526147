import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './core/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/app/tabs/event',
    pathMatch: 'full'
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule)
  },
  {
    path: 'event-detail',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/event-detail/event-detail.module').then( m => m.EventDetailPageModule)
  },
  {
    path: 'participation-confirmations',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/participation-confirmations/participation-confirmations.module')
      .then( m => m.ParticipationConfirmationsPageModule)
  },
  {
    path: 'participation-confirmations/details/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/participation-confirmations-detail/participation-confirmations-detail.module')
      .then(m => m.ParticipationConfirmationsDetailPageModule)
  },
  {
    path: 'participation-confirmations/feedback/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/participation-feedback/participation-feedback.module').then( m => m.ParticipationFeedbackPageModule)
  },
  {
    path: 'exhibitors',
    loadChildren: () => import('./pages/exhibitors/exhibitors.module').then( m => m.ExhibitorsPageModule)
  },
  {
    path: 'my-workshops',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/my-workshops/my-workshops.module').then( m => m.MyWorkshopsPageModule)
  },
  {
    path: 'my-workshops/details/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/my-workshops-detail/my-workshops-detail.module').then( m => m.MyWorkshopsDetailPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/registration/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'register-confirm',
    loadChildren: () => import('./pages/registration/register-confirm/register-confirm.module').then( m => m.RegisterConfirmPageModule)
  },
  {
    path: 'register-success',
    loadChildren: () => import('./pages/registration/register-success/register-success.module').then( m => m.RegisterSuccessPageModule)
  },
  {
    path: 'workshop-detail/:id',
    loadChildren: () => import('./pages/workshop-detail/workshop-detail.module').then( m => m.WorkshopDetailPageModule)
  },
  {
    path: 'exhibitors/:id',
    loadChildren: () => import('./pages/exhibitor-detail/exhibitor-detail.module').then( m => m.ExhibitorDetailPageModule)
  },
  {
    path: 'speaker-confirmations',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/speaker-confirmations/speaker-confirmations.module').then( m => m.SpeakerConfirmationsPageModule)
  },
  {
    path: 'speaker-confirmations/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/speaker-confirmations-detail/speaker-confirmations-detail.module').then( m => m.SpeakerConfirmationsDetailPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'password-reset-confirm',
    loadChildren: () => import('./pages/password/password-reset-confirm/password-reset-confirm.module').then( m => m.PasswordResetConfirmPageModule)
  },
  {
    path: 'password-reset-sent',
    loadChildren: () => import('./pages/password/password-reset-sent/password-reset-sent.module').then( m => m.PasswordResetSentPageModule)
  },
  {
    path: 'exhibitor-data',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/exhibitor-data/exhibitor-data.module').then( m => m.ExhibitorDataPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
