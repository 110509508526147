import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {forwardRef, NgModule, Provider} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {IonicModule, ModalController} from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EventService} from './services/event.service';
import {ApiModule} from './api/api.module';
import {ApiService} from './api/services';
import {ToastService} from './services/toast.service';
import {AuthenticationInterceptor} from './services/interceptors/authentication-interceptor';
import {ComponentsModule} from './components/components.module';
import {AuthGuard} from './core/guard/auth.guard';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {AppVersion} from '@awesome-cordova-plugins/app-version';

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => AuthenticationInterceptor),
  multi: true
};

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ApiModule.forRoot({ rootUrl: environment.apiUrl }),
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent,],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    InAppBrowser,
    SplashScreen,
    StatusBar,
    ModalController,
    AuthenticationInterceptor,
    API_INTERCEPTOR_PROVIDER,
    AuthGuard,
    EventService,
    ApiService,
    ToastService,
    //AppVersion
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
