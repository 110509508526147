import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {Account, PasswordResetConfirm, PatchedAccount, SetPassword, SetUsername, TokenObtainPair} from '../api/models';
import {catchError, finalize, switchMap} from 'rxjs/operators';
import {ApiService} from '../api/services/api.service';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {ToastService} from './toast.service';
import {AccountProfilePicture} from '../api/models/account-profile-picture';


@Injectable({
  providedIn: 'root'
})
export class UserService  {

  private loggedInBehaviorSubject = new BehaviorSubject<boolean>(false);
  private accountDataBehaviorSubject = new BehaviorSubject<Account>(undefined);
  userEmail: string;
  account: Account;

  constructor(private storage: StorageService,
              private api: ApiService,
              private router: Router,
              private toast: ToastService,
              ) {
    this.init();
  }

  init() {

    this.loggedInBehaviorSubject.next(false);
    this.account = {
      birthday: undefined,
      date_joined: '',
      email: '',
      is_active: true,
      is_staff: false,
      is_superuser: false,
      is_user: false,
      is_lecturer: false,
      is_exhibitor: false,
      as_customernumber: '',
      last_login: undefined,
      profile_description: undefined,
      profile_picture: undefined,
      schooltype: undefined,
      first_name: '',
      last_name: '',
      has_active_subscription: false,
      id: 1
    };


    this.loggedInBehaviorSubject.next(false);
    this.userEmail = null;

    this.getAuthToken().then(x => {
      if (!!x) {
        // assume if we have a auth token we are logged in
        this.refreshAccount();
      }
    });
  }

  // region Auth token

  public getAuthToken(): Promise<string> {
    return this.storage.getAuthToken();
  }

  public setAuthToken(value: string): Promise<any> {
    return this.storage.setAuthToken(value);
  }

  public clearAuthToken(): void {
    this.storage.clearAuthToken();
  }

  public async getRefreshToken(): Promise<string> {
    return await this.storage.getRefreshToken();
  }

  public setRefreshToken(value: string): void {
    this.storage.setRefreshToken(value);
  }

  public clearRefreshToken(): void {
    this.storage.clearRefreshToken();
  }

  public getAccount() {
    return this.accountDataBehaviorSubject.asObservable();
  }

  public deleteAccount(password: string){
    let result = false;
    return new Promise((resolve) => {
      this.api.apiAccountDeleteCreate$Json({ body: {current_password: password}}).pipe(
        switchMap((response) => {
          result = true;
          this.toast.showSuccess('Account gelöscht');
          this.logOut();
          return of(true);
        }),
        finalize(() => resolve(result)),
        catchError((err) => {
          if(err.status === 401){
            this.toast.showError('Benutzername oder Passwort falsch');
            return of(err);
          }
          if (err.hasOwnProperty('error')) {
            this.toast.showError(err.error.Error);
          } else {
            this.toast.showError('Fehler beim Löschen');
          }
          return of(false);
        })
      ).subscribe();
    })
  }

  public refreshAccount() {
    return this.api.apiAccountRetrieve().subscribe( x => {
      this.accountDataBehaviorSubject.next(x);
      if(x != null){
        this.loggedInBehaviorSubject.next(true);
      }
    } );
  }

  public login(loginData: TokenObtainPair): Promise<boolean> {
    let result = false;

    return new Promise((resolve) => {
      this.api.apiAuthJwtCreateCreate$Json({body: loginData}).pipe(
        switchMap((response) => {
          result = true;
          return this.setAuthToken(response.access).then(x => {
            this.setRefreshToken(response.refresh);
            return this.afterLogin().subscribe();
          });
        }),
        finalize(() => resolve(result)),
        catchError((err) => {
          if(err.status === 401){
            this.toast.showError('Benutzername oder Passwort falsch');
            return of(err);
          }
          if (err.hasOwnProperty('error')) {
            this.toast.showError(err.error.Error);
          } else {
            this.toast.showError('Fehler beim Anmelden');
          }

          return of(false);
        })
      ).subscribe();
    });
  }

  public afterLogin(): any {

    return this.api.apiAccountRetrieve().pipe(
      switchMap((response) => {
          this.accountDataBehaviorSubject.next(response);
          window.dispatchEvent(new CustomEvent('user:login'));
          this.loggedInBehaviorSubject.next(true);
          if(response.has_active_subscription){
            this.router.navigate(['/app/tabs/schedule']);
          }else{
            this.router.navigate(['/app/tabs/event']);
          }

          return of(response)
        }
      ));

    /*
    this.api.apiAccountRetrieve().subscribe( x => {
      this.accountDataBehaviorSubject.next(x);
      window.dispatchEvent(new CustomEvent('user:login'));
      this.loggedInBehaviorSubject.next(true);
    });
    */
  }

  public logOut(): void {
    this.clearAuthToken();
    this.clearRefreshToken();
    this.init();
    window.dispatchEvent(new CustomEvent('user:logout'));
    this.router.navigate(['/login']);
  }

  public signUp(): void {
    window.dispatchEvent(new CustomEvent('user:signup'));
  }

  isLoggedIn$(): Observable<boolean> {
    return this.loggedInBehaviorSubject;
  }


  public saveAccount(account: PatchedAccount){
    const ob = this.api.apiAuthUsersMePartialUpdate$FormData({body: account});
    return ob.pipe(
    );
  }

  public getAccountQR(){
    return this.api.apiUsersQrRetrieve();
  }

  public updateUserPicture(file){

    const pictureData: AccountProfilePicture = {filename: 'Profile picture', file};
    return this.api.apiUsersPictureUpdate$FormData({ body: pictureData});
  }

  // tslint:disable-next-line:variable-name
  public setPassword(current_password: string, new_password: string){

    const data: SetPassword = {current_password, new_password};
    return this.api.apiAuthUsersSetPasswordCreate$Json({body: data});
  }

  // tslint:disable-next-line:variable-name
  public setEmail(current_password: string, new_email: string){

    const data: SetUsername = {current_password, new_email};
    return this.api.apiAuthUsersSetEmailCreate$Json({ body: data});
  }

  public resetPassword(email: string){

    return this.api.apiAuthUsersResetPasswordCreate$Json({ body: { email }}).subscribe(
      x => {
        this.toast.presentToast('Passwort Rücksetzung gesendet').then(
          y => {
            this.router.navigate(['/password-reset-sent']);
          }
        );

      },
      error => {
        this.toast.showError('Fehler bei der Passwortrücksetzung');
      }
    )
  }

  public resetPasswordConfirm(data: PasswordResetConfirm){

    return this.api.apiAuthUsersResetPasswordConfirmCreate$Json( { body: data});
  }

}
