<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content">
      <ion-content>
        <div class="menu-logo">
          <img src="assets/img/logo/logo.png" alt="BLLV Logo">
        </div>
        <ion-list lines="none">
          <ion-list-header>
            Veranstaltungen
          </ion-list-header>
          <!--
          <ion-menu-toggle autoHide="false" *ngFor="let p of appPages; let i = index">
            <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>

          </ion-menu-toggle>
          -->
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/app/tabs/event" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" name="calendar"></ion-icon>
              <ion-label>
                Veranstaltungen
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle  *ngIf="loggedIn" autoHide="false">
            <ion-item routerLink="/app/tabs/schedule" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" name="calendar"></ion-icon>
              <ion-label>
                Workshops
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="loggedIn" autoHide="false">
            <ion-item routerLink="/app/tabs/speakers" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" name="school"></ion-icon>
              <ion-label>
                Referenten
              </ion-label>
            </ion-item>
          </ion-menu-toggle>


          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/exhibitors" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>
                Aussteller
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list *ngIf="loggedIn && account.is_lecturer" lines="none">
          <ion-list-header>
            Referentenmenü
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/my-workshops" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="clipboard"></ion-icon>
              <ion-label>
                Meine Workshops
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/speaker-confirmations" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="calendar"></ion-icon>
              <ion-label>
                Meine Abrechnungen
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list *ngIf="loggedIn && account.is_exhibitor" lines="none">
          <ion-list-header>
            Ausstellermenü
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/exhibitor-data" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="library"></ion-icon>
              <ion-label>
                Tisch / Bodenfläche
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>



        <ion-list *ngIf="loggedIn" lines="none">
          <ion-list-header>
            Benutzerkonto
          </ion-list-header>

          <ion-menu-toggle *ngIf="loggedIn" autoHide="false">
            <ion-item routerLink="/participation-confirmations" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="document-text"></ion-icon>
              <ion-label>
                Teilnahmebestätigungen
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/account" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="person"></ion-icon>
              <ion-label>
                Benutzerkonto
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="logout()" detail="false">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>
                Abmelden
              </ion-label>
            </ion-item>
          </ion-menu-toggle>



        </ion-list>

        <ion-list *ngIf="!loggedIn" lines="none">
          <ion-list-header>
            Benutzerkonto
          </ion-list-header>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/login" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="log-in"></ion-icon>
              <ion-label>
                Anmelden
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/register" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="person-add"></ion-icon>
              <ion-label>
                Registrieren
              </ion-label>
            </ion-item>
          </ion-menu-toggle>


        </ion-list>

        <ion-list lines="none">
          <ion-list-header>
            Sonstiges
          </ion-list-header>

          <!--
          <ion-menu-toggle *ngIf="loggedIn" autoHide="false">
            <ion-item routerLink="/support" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="help"></ion-icon>
              <ion-label>
                Support
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          -->

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/app/tabs/about" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="information-circle"></ion-icon>
              <ion-label>
                über diese App
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>

</ion-app>
