import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {EMPTY, from, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {UserService} from '../user.service';
import {CustomHeaders} from '../../interfaces/custom-headers';
import {ApiService} from '../../api/services/api.service';
import {TokenRefresh} from '../../api/models/token-refresh';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class AuthenticationInterceptor {

  constructor(
    private user: UserService,
    private api: ApiService,
  ) {
  }

  private static readonly authTokenHeaderPrefix = 'Bearer ';

  private static addAuthenticationToken(request: HttpRequest<any>, authToken: string): HttpRequest<any> {

    if(authToken === null || authToken === '' || authToken === undefined){
      return request;
    }

    return request.clone({headers:
        request.headers.set(CustomHeaders.Authorization, AuthenticationInterceptor.authTokenHeaderPrefix + authToken)});
  }

  public static extractAuthToken(response: HttpResponse<TokenRefresh>, user: UserService): string {
    const authToken = response.body.access;
    user.setAuthToken(authToken);

    const refreshToken = response.body.refresh;
    user.setRefreshToken(refreshToken);

    return authToken;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // skip any auth headers if we st the header like
    // const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    if (request.url === '/api/auth/users/activation/' || request.url === 'api/auth/users/'){
      return next.handle(request.clone({ headers: request.headers }));
    }

    // Apply the headers

    // Promise to Obserable and return a Observable
    return from(this.user.getAuthToken()).pipe(
      mergeMap(token => {
        return next.handle(AuthenticationInterceptor.addAuthenticationToken(request, token))
          .pipe(
            catchError(error => {
              if (error.error.code === 'token_not_valid') {
                const refreshTokenPromise = this.user.getRefreshToken();

                return from(refreshTokenPromise).pipe(mergeMap(
                  refreshToken => {
                    return this.api.apiAuthJwtRefreshCreate$Json$Response({body: {refresh: refreshToken, access: null}}).pipe(
                      catchError(() => {
                        this.user.logOut();
                        return EMPTY;
                      })
                      ,
                      switchMap((response) => {
                        console.log('Token refresh successful!');
                        const newToken = AuthenticationInterceptor.extractAuthToken(response, this.user);
                        request = AuthenticationInterceptor.addAuthenticationToken(request, newToken);
                        return next.handle(request);
                      })
                    );

                  })
                );

              } else {
                throw error;
              }
            })
          );
      })
    );

  }

  private extractAuthTokenIfLoggedIn(request: HttpRequest<any>, event: HttpEvent<any>): void {
    if (!(event instanceof HttpResponse)) {
      return;
    }
    AuthenticationInterceptor.extractAuthToken(event as HttpResponse<any>, this.user);
  }

}
